import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'deviceHealthMonitoring',
    sourceId: 'LD-2024-03-01-monitoring',
  },
  {
    localName: 'synthesizedReports',
    sourceId: 'LD-2021-04-01-synthesized-reports',
  },
  {
    localName: 'workflowsManagement',
    sourceId: 'LD-2024-07-15-request-approval-workflow',
  },
  {
    localName: 'policyManagementDeliveryType',
    sourceId: 'TH-750-ap-policy-management-delivery-type',
  },
]);
